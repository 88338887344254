<template>
  <div>
    <v-btn v-if="questions.length && !currentUser.isBroadcaster" fab :color="colors.success" @click="examDialog = true" class="examBtn" :disabled="!examAvailable">
      <v-icon>mdi-beaker-question-outline</v-icon>
    </v-btn>

    <v-dialog v-model="examDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar
            dark
            :color="colors.success"
        >
          <v-btn
              icon
              dark
              @click="examDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title :class="[ltrMode ? 'ltr-font' : 'rtl-font']">{{ $t('meeting.exam') }}</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-row :style="{direction: ltrMode ? 'ltr' : 'rtl', textAlign: ltrMode ? 'left' : 'right', padding: '20px'}">
            <v-col cols="12" v-for="(question, index) in questions"
                   :key="question.id"
                   :class="[ltrMode ? 'ltr-font' : 'rtl-font']">
              <h3 :style="{direction: ltrMode ? 'ltr!important' : 'rtl!important', textAlign: ltrMode ? 'left!important' : 'right!important'}">{{ question.title }}</h3>
              <p :style="{direction: ltrMode ? 'ltr!important' : 'rtl!important', textAlign: ltrMode ? 'left!important' : 'right!important'}">{{ question.description }}</p>
              <v-radio-group v-model="answers[index].answer_id">
                <v-radio
                    v-for="answer in question.answers"
                    :key="answer.id"
                    :label="answer.answer"
                    :value="answer.id"
                ></v-radio>
              </v-radio-group>
              <v-divider></v-divider>
            </v-col>
            <v-btn :color="colors.success" rounded class="white--text mb-4 mt-4" @click="finishExam" :class="[ltrMode ? 'ltr-font' : 'rtl-font']">
              {{ $t('meeting.submit') }}
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "MeetingExam",

  props: [
    'currentUser', 'axios', 'meetingId', 'ltrMode', 'colors'
  ],

  data() {
    return {
      questions: [],
      answers: [],
      examDialog: false,
      examAvailable: true,
    }
  },

  methods: {
    getQuestions() {
      this.axios.get('api/web-rtc/meeting/exam/questions/index/' + this.meetingId).then((res) => {
        this.examAvailable = res.data.data.is_available
        this.questions = res.data.data.questions

        this.questions.forEach(() => {
          this.answers.push({
            answer_id: null
          })
        })
      }).catch((err) => {
        console.log(err)
      })
    },

    finishExam() {
      this.axios.post('api/web-rtc/meeting/exam/answer', {
        answers: this.answers,
        meeting_id: this.meetingId
      }).then(() => {
        this.examAvailable = false
        this.examDialog = false
      }).catch((err) => {
        console.log(err)
      })
    }
  },

  mounted() {
    this.getQuestions()
  }
}
</script>

<style scoped>
.examBtn {
  box-shadow: none !important;
  position: fixed !important;
  bottom: 20px !important;
  right: 70px !important;
  color: white !important;
}
</style>