<template>
  <div :style="{direction: ltrMode ? 'ltr!important;' : 'rtl!important;', textAlign: ltrMode ? 'left' : 'right'}">
    <div v-if="watermark_dynamic" :class="[ltrMode ? 'ltr-font' : 'rtl-font', 'watermark']">{{ currentUser.name }}</div>
    <img class="watermark" v-else :src="watermark_url"/>
    <!--  WHITEBOARD  -->
    <v-row v-if="whiteBoardStatus && broadcasterIsInChannel && !loading && !errors.length">
      <v-col cols="12" class="mt-1">
        <h3 v-if="ltrMode" class="ltr-font">
          <v-btn fab :color="colors.success" @click="navigate(1)" x-small>
            <v-icon color="white">mdi-arrow-left</v-icon>
          </v-btn>
          {{ $t("meeting.page") }} {{ paintNumber }}
          <v-btn fab :color="colors.success" :disabled="paintNumber === 1" @click="navigate(-1)" x-small>
            <v-icon color="white">mdi-arrow-right</v-icon>
          </v-btn>
        </h3>
        <h3 v-else class="rtl-font">
          <v-btn fab :color="colors.success" :disabled="paintNumber === 1" @click="navigate(-1)" x-small>
            <v-icon color="white">mdi-arrow-right</v-icon>
          </v-btn>
          {{ $t("meeting.page") }} {{ paintNumber }}
          <v-btn fab :color="colors.success" @click="navigate(1)" x-small>
            <v-icon color="white">mdi-arrow-left</v-icon>
          </v-btn>
        </h3>
      </v-col>
      <v-col id="col-of-paintable" cols="12" xl="9" lg="8" md="8" sm="12" xs="12">
        <div v-if="whiteBoardWidth">
          <paintable
              alwaysOnTop
              :active="paintConfigStatus"
              :name="'screen-' + paintNumber"
              :width="whiteBoardWidth"
              :height="450"
              :factor="1"
              :lineWidth="dynamicLineWidth"
              :lineWidthEraser="20"
              :color="color"
              class="paint"
              ref="paintable"
              disableNavigation
              @toggle-paintable="toggledPaintable"
          >
          </paintable>
        </div>
      </v-col>
      <v-col cols="12" xl="3" lg="4" md="4" sm="12" xs="12">
        <div class="custom-navigation" v-if="paintConfigStatus">
          <v-color-picker
              dot-size="10"
              swatches-max-height="200"
              v-model="color"
              hide-inputs
              style="margin: auto"
          ></v-color-picker>
          <v-slider
              track-fill-:color="colors.success"
              max="100"
              v-model="dynamicLineWidth"
              min="1"
          ></v-slider>

          <v-row>
            <v-col style="text-align: center!important;">
              <v-btn @click="$refs.paintable.undoDrawingStep" fab :color="colors.success" small>
                <v-icon color="white">mdi-undo</v-icon>
              </v-btn>
            </v-col>
            <v-col style="text-align: center!important;">
              <v-btn @click="$refs.paintable.redoDrawingStep" fab :color="colors.success" small>
                <v-icon color="white">mdi-redo</v-icon>
              </v-btn>
            </v-col>
            <v-col style="text-align: center!important;">
              <v-btn @click="$refs.paintable.clearCanvas" fab :color="colors.success" small>
                <v-icon color="white">mdi-cached</v-icon>
              </v-btn>
            </v-col>
            <v-col style="text-align: center!important;">
              <v-btn @click="$refs.paintable.saveCurrentCanvasToStorage" fab :color="colors.success" small>
                <v-icon color="white">mdi-content-save</v-icon>
              </v-btn>
            </v-col>
          </v-row>

        </div>
      </v-col>
    </v-row>

    <!--  BROADCASTER NOT IN CHANNEL ERROR  -->
    <div class="fullscreen_center_box_absolute"
         :style="{display: broadcasterIsInChannel === false && !showScore ? '' : 'none'}">
      <v-alert
          :class="[ltrMode ? 'ltr-font' : 'rtl-font']"
          :color="colors.danger"
          type="warning"
      >
        <span>{{ $t('meeting.host_not_present') }}</span>
      </v-alert>
    </div>

    <!--  LOADING  -->
    <div v-if="loading" class="fullscreen_center_box">
      <v-progress-circular
          :size="70"
          :width="7"
          color="purple"
          indeterminate
      ></v-progress-circular>
    </div>

    <!--  ERRORS  -->
    <div v-else-if="errors.length" class="fullscreen_center_box">
      <v-alert
          :class="[ltrMode ? 'ltr-font' : 'rtl-font']"
          :color="colors.danger"
          type="warning"
      >
        <span v-for="(error, index) in errors" :key="index">
          {{ error }}
          <br>
        </span>
      </v-alert>
    </div>

    <!--  Score  -->
    <div v-else-if="showScore" class="fullscreen_center_box">
      <v-card
          :class="[ltrMode ? 'ltr-font' : 'rtl-font']"
          class="elevation-16 mx-auto"
          style="border-radius: 25px;"
          width="500"
      >
        <v-card-title class="text-h5 justify-center">
          <span v-if="!scoreSubmitted">Rate Our Platform</span>
          <span v-else>Thank You For Your Feedback!</span>
        </v-card-title>
        <v-card-text>
          <div class="text-center mt-5">
            <v-rating
                style="text-align: center!important"
                :readonly="scoreSubmitted"
                :background-color="colors.success"
                :color="colors.success"
                empty-icon="mdi-star-outline"
                full-icon="mdi-star"
                hover
                length="5"
                size="55"
                v-model="score"
            ></v-rating>
          </div>
        </v-card-text>
        <v-card-actions v-if="!scoreSubmitted" class="justify-center">
          <v-btn :color="colors.success" rounded @click="submitScore" class="white--text">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <!--  MAIN VIDEOS  -->
    <v-row v-else class="pa-10">
      <!--  SELF VIDEO  -->
      <v-col cols="12" xl="3" lg="4" md="6" sm="12" xs="12">
        <v-card class="bordered-card" id="self-card">
          <video
              style="border-radius: 15px!important; height: 97%"
              width="100%"
              ref="self"
              muted
              playsinline
              autoplay
          />
          <div class="under-video-layer-self">
            <v-btn type="button" fab x-small :color="colors.success" class="white--text"
                   @click="toggleFullScreen('self-card')">
              <v-icon>mdi-fullscreen</v-icon>
            </v-btn>
            <v-btn v-if="microphonePermission" type="button" fab x-small :color="colors.success"
                   :class="[ltrMode ? 'ml-1' : 'mr-1']" class="white--text" @click="toggleMuteAudio">
              <v-icon>{{ mutedAudio ? "mdi-microphone-off" : "mdi-microphone" }}</v-icon>
            </v-btn>
            <v-btn v-if="webcamPermission" type="button" fab x-small :color="colors.success"
                   :class="[ltrMode ? 'ml-1' : 'mr-1']" class="white--text" @click="toggleMuteVideo">
              <v-icon>{{ mutedVideo ? "mdi-webcam-off" : "mdi-webcam" }}</v-icon>
            </v-btn>
            <v-btn v-if="screenPermission" type="button" fab x-small :color="colors.success"
                   :class="[ltrMode ? 'ml-1' : 'mr-1']" class="white--text" @click="toggleShareScreen">
              <v-icon>{{ screenIsSharing ? "mdi-projector-screen" : "mdi-projector-off" }}</v-icon>
            </v-btn>
            <v-btn v-if="whiteboardPermission" type="button" fab x-small :color="colors.success"
                   :class="[ltrMode ? 'ml-1' : 'mr-1']" class="white--text" @click="toggleWhiteBoard">
              <v-icon>{{ whiteBoardStatus ? "mdi-brush" : "mdi-brush-off" }}</v-icon>
            </v-btn>
            <v-btn v-if="videoPermission" :disabled="videoFileIsSharing" type="button" fab x-small
                   :color="colors.success" :class="[ltrMode ? 'ml-1' : 'mr-1']" class="white--text"
                   @click="selectVideo">
              <v-icon>mdi-file-video</v-icon>
            </v-btn>
            <input @change="videoFileSelected" type="file" id="video-file-input" style="display: none" accept="video/*">
            <v-btn v-if="audioPermission" :disabled="audioFileIsSharing" type="button" fab x-small
                   :color="colors.success" :class="[ltrMode ? 'ml-1' : 'mr-1']" class="white--text"
                   @click="selectAudio">
              <v-icon>mdi-file-music</v-icon>
            </v-btn>
            <input @change="audioFileSelected" type="file" id="audio-file-input" style="display: none" accept="audio/*">
            <v-btn v-if="recordPermission" type="button" fab x-small :color="colors.success"
                   :class="[ltrMode ? 'ml-1' : 'mr-1']" class="white--text" @click="toggleRecord">
              <v-icon v-if="!isRecording">mdi-record-rec</v-icon>
              <v-icon v-else-if="recordIcon === 1">mdi-record</v-icon>
              <v-icon v-else-if="recordIcon === 2">mdi-record-circle</v-icon>
            </v-btn>
            <v-chip small class="current-user-chip" :color="colors.danger" :class="[ltrMode ? 'ltr-font' : 'rtl-font']">
              {{ currentUser.name }}
            </v-chip>
          </div>
        </v-card>
      </v-col>
      <!--  OTHER USERS VIDEOS  -->
      <v-col cols="12" xl="3" lg="4" md="6" sm="12" xs="12" v-for="user in users" :key="user.id">
        <v-card class="bordered-card" :id="'user-video-card-' + user.id">
          <video
              style="border-radius: 15px!important; height: 97%"
              width="100%"
              :ref="'v' + user.id"
              playsinline
              autoplay
          />
          <div :class="[ltrMode ? 'ltr-font' : 'rtl-font', 'under-video-layer']">
            <v-btn type="button" fab x-small :color="colors.success" class="white--text"
                   @click="toggleFullScreen('user-video-card-' + user.id)">
              <v-icon>mdi-fullscreen</v-icon>
            </v-btn>
            <v-chip small class="user-chip" v-if="user.isBroadcaster">{{ $t('meeting.host') }} : {{
                user.name
              }}
            </v-chip>
            <v-chip small class="user-chip" v-else>{{ user.name }}</v-chip>
          </div>
        </v-card>
      </v-col>

      <!--  SELF SCREEN  -->
      <v-col cols="12" xl="3" lg="4" md="6" sm="12" xs="12" :style="{display: screenIsSharing ? '' : 'none'}">
        <v-card class="bordered-card" id="screen-card">
          <video
              style="border-radius: 15px!important; height: 97%"
              width="100%"
              ref="screen"
              muted
              playsinline
              autoplay
          />
          <div class="under-video-layer" :class="[ltrMode ? 'ltr-font' : 'rtl-font']">
            <v-btn type="button" fab x-small :color="colors.success" class="white--text"
                   @click="toggleFullScreen('screen-card')">
              <v-icon>mdi-fullscreen</v-icon>
            </v-btn>
            <v-chip small class="current-user-chip" :color="colors.danger">
              {{ $t('meeting.your_screen') }}
            </v-chip>
          </div>
        </v-card>
      </v-col>
      <!--  OTHER USERS SCREENS  -->
      <v-col cols="12" xl="3" lg="4" md="6" sm="12" xs="12" v-for="screen in screens" :key="screen.ref">
        <v-card class="bordered-card" :id="'screen-card-user-' + screen.ref">
          <video
              style="border-radius: 15px!important; height: 97%"
              width="100%"
              :ref="screen.ref"
              playsinline
              autoplay
          />
          <div class="under-video-layer" :class="[ltrMode ? 'ltr-font' : 'rtl-font']">
            <v-btn type="button" fab x-small :color="colors.success" class="white--text"
                   @click="toggleFullScreen('screen-card-user-' + screen.ref)">
              <v-icon>mdi-fullscreen</v-icon>
            </v-btn>
            <v-chip small class="user-chip">
              {{ screen.user.name }}{{ $t('meeting.someones_screen') }}
            </v-chip>
          </div>
        </v-card>
      </v-col>

      <!--  OTHER USERS WHITEBOARD  -->
      <v-col cols="12" xl="3" lg="4" md="6" sm="12" xs="12" v-for="whiteboard in whiteboards" :key="whiteboard.ref">
        <v-card class="bordered-card" :id="'whiteboard-card-' + whiteboard.ref">
          <video
              style="border-radius: 15px!important; height: 97%"
              width="100%"
              :ref="whiteboard.ref"
              playsinline
              autoplay
          />
          <div class="under-video-layer" :class="[ltrMode ? 'ltr-font' : 'rtl-font']">
            <v-btn type="button" fab x-small :color="colors.success" class="white--text"
                   @click="toggleFullScreen('whiteboard-card-' + whiteboard.ref)">
              <v-icon>mdi-fullscreen</v-icon>
            </v-btn>
            <v-chip small class="user-chip">
              {{ whiteboard.user.name }}{{ $t('meeting.someones_whiteboard') }}
            </v-chip>
          </div>
        </v-card>
      </v-col>

      <!--  Video File  -->
      <v-col cols="12" xl="3" lg="4" md="6" sm="12" xs="12" v-if="videoFileIsSharing">
        <v-card class="bordered-card">
          <video
              style="border-radius: 15px!important; height: 97%"
              width="100%"
              ref="video-file"
              :autoplay="videoFileSharer.id != currentUser.id"
              controls
          />
          <div class="under-video-layer" :class="[ltrMode ? 'ltr-font' : 'rtl-font']">
            <v-btn v-if="videoFileSharer.id == currentUser.id" type="button" fab small :color="colors.success"
                   class="white--text" @click="cancelVideoSharing">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-chip style="float: left; margin-top: 4px" v-else>
              {{ videoFileSharer.name }} {{ $t('meeting.is_sharing_video_file') }}
            </v-chip>
          </div>
        </v-card>
      </v-col>

      <!--  Audio File  -->
      <v-col cols="12" xl="3" lg="4" md="6" sm="12" xs="12" v-if="audioFileIsSharing">
        <v-card class="bordered-card">
          <div style="text-align: center">
            <audio
                ref="audio-file"
                :autoplay="audioFileSharer.id != currentUser.id"
                controls
            />
          </div>
          <div class="under-video-layer" :class="[ltrMode ? 'ltr-font' : 'rtl-font']">
            <v-btn v-if="audioFileSharer.id == currentUser.id" type="button" fab small :color="colors.success"
                   class="white--text" @click="cancelAudioSharing">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-chip style="float: left; margin-top: 4px" v-else>
              {{ audioFileSharer.name }} {{ $t('meeting.is_sharing_audio_file') }}
            </v-chip>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <meeting-comment v-if="!loading && broadcasterIsInChannel"
                     :currentUser="currentUser"
                     :channel-name="channel"
                     :meeting-id="meeting_id"
                     :axios="axiosInstance"
                     :echo="Echo"
                     :isBroadcaster="currentUser.isBroadcaster"
                     :ltrMode="ltrMode"
                     :colors="colors"
    ></meeting-comment>

    <meeting-exam v-if="!loading"
                  :currentUser="currentUser"
                  :meeting-id="meeting_id"
                  :axios="axiosInstance"
                  :ltrMode="ltrMode"
                  :colors="colors"
    ></meeting-exam>

    <meeting-setting v-if="!loading && !errors.length"
                     :axios="axiosInstance"
                     :meeting-id="meeting_id"
                     :ltrMode="ltrMode"
                     :clas="clas"
                     :echo="Echo"
                     :channel="channel"
                     :currentUser="currentUser"
                     :colors="colors"
                     v-on:meeting-ended="endMeeting"
    ></meeting-setting>
  </div>
</template>

<script>
import {getPermissions} from "../../helpers";

import Peer from "simple-peer";
import Echo from "laravel-echo";
import axios from "axios";
import MeetingComment from "../../components/Meeting/MeetingComment";
import MeetingSetting from "../../components/Meeting/MeetingSetting";
import MeetingExam from "../../components/Meeting/MeetingExam";

export default {
  name: "Meeting",
  components: {MeetingExam, MeetingSetting, MeetingComment},
  data() {
    return {
      /* Page Config */
      ltrMode: false,
      loading: true,
      errors: [],

      /* Instances */
      axiosInstance: null,
      Echo: null,
      webSocketChannel: null,
      meetingOngoingInterval: null,

      /* Turn Credentials */
      turn_url: 'turn:88.135.36.51:3478',
      turn_username: 'vod',
      turn_credential: '0520',

      /* General Data */
      clas: null,
      meeting_id: null,
      broadcaster_id: null,
      broadcasterIsInChannel: null,
      channel: null,
      token: null,
      watermark_dynamic: null,
      watermark_url: null,

      /* Users */
      users: [],
      currentUser: {},

      /* Peer Connections */
      peers: {},
      screens: [],
      whiteboards: [],

      /* Permissions */
      microphonePermission: null,
      webcamPermission: null,
      screenPermission: null,
      whiteboardPermission: null,
      videoPermission: null,
      audioPermission: null,
      recordPermission: null,

      /* Whiteboard Config */
      whiteBoardStatus: false,
      paintNumber: 1,
      dynamicLineWidth: 5,
      paintConfigStatus: false,
      color: '#000',
      whiteBoardWidth: null,

      /* General Config */
      mutedAudio: false,
      mutedVideo: false,

      /* Screen Config */
      screenIsSharing: false,

      /* Video File Config*/
      videoFileIsSharing: false,
      videoFileSharer: null,

      /* Audio File Config */
      audioFileIsSharing: false,
      audioFileSharer: null,

      /* Recorder Config */
      isRecording: false,
      recordInterval: null,
      recordIcon: 1,
      mediaRecorder: null,

      /* Score */
      showScore: false,
      score: 0,
      scoreSubmitted: false,

      /* Theme */
      colors: {
        success: '',
        warning: '',
        danger: '',
      }
    }
  },

  mounted() {
    if (this.$route.path == '/meeting/ltr') {
      this.ltrMode = true
      this.$vuetify.rtl = false;
      this.$vuetify.ltr = true;
    } else {
      this.$i18n.locale = 'fa'
    }

    /* GET NECESSARY INFORMATION */
    let meeting_token = this.$route.query.token

    axios.post('/api/web-rtc/join', {
      meeting_token: meeting_token,
    }).then((res) => {
      this.watermark_dynamic = res.data.data.watermark_dynamic
      this.watermark_url = res.data.data.watermark_url
      this.clas = res.data.data.clas
      this.meeting_id = res.data.data.meeting_id
      this.channel = res.data.data.channel
      this.broadcaster_id = res.data.data.broadcaster_id
      this.currentUser.id = res.data.data.user_id
      if (this.broadcaster_id == this.currentUser.id) {
        this.broadcasterIsInChannel = true
        this.currentUser.isBroadcaster = true
      }
      this.currentUser.name = res.data.data.user_name

      this.microphonePermission = res.data.data.permissions.microphone
      this.webcamPermission = res.data.data.permissions.webcam
      this.screenPermission = res.data.data.permissions.screen
      this.whiteboardPermission = res.data.data.permissions.whiteboard
      this.videoPermission = res.data.data.permissions.video
      this.audioPermission = res.data.data.permissions.audio
      this.recordPermission = res.data.data.permissions.record

      this.colors.success = res.data.data.template.success
      this.colors.warning = res.data.data.template.warning
      this.colors.danger = res.data.data.template.danger

      this.token = res.data.data.token
      this.loading = false

      /* AXIOS CONFIGURATION */
      axios.defaults.baseURL = process.env.VUE_APP_BASE_URL || "http://localhost:8000/";
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
      axios.defaults.headers.post['Content-Type'] = 'application/json';

      this.axiosInstance = axios
      this.initialMeeting()
    }).catch((err) => {
      console.log(err)
      this.errors.push(err.response.data.message)
      this.loading = false
    })
  },

  methods: {
    async initialMeeting() {
      /* INITIAL ECHO INSTANCE */
      this.Echo = new Echo({
        broadcaster: 'pusher',
        key: 'myKey',
        cluster: 'mt1',
        wsHost: 'socket.hivelink.co',
        wsPort: 443,
        forceTLS: true,
        disableStats: true,
        encrypted: true,
        auth: {
          headers: {
            Authorization: 'Bearer ' + this.token
          }
        },
        authorizer: (channel) => {
          return {
            authorize: (socketId, callback) => {
              axios.post('/api/broadcasting/auth', {
                socket_id: socketId,
                channel_name: channel.name
              })
                  .then(response => {
                    callback(false, response.data);
                  })
                  .catch(error => {
                    callback(true, error);
                  });
            }
          };
        },
      });

      /* GET USER AUDIO AND VIDEO */
      this.$refs.self.srcObject = await getPermissions(true, true);
      if (!this.webcamPermission) {
        this.toggleMuteVideo()
      }
      if (!this.microphonePermission) {
        this.toggleMuteAudio()
      }

      /* JOIN MAIN STREAM CHANNEL */
      this.webSocketChannel = this.Echo.join("streaming-channel." + this.channel);

      /* UPDATE USAGE */
      this.meetingOngoingInterval = setInterval(() => {
        if (!this.errors.length && this.currentUser.isBroadcaster) {
          axios.post('/api/web-rtc/stream/usage', {
            seconds: 15000 / 1000,
            meeting_token: this.$route.query.token,
          }).catch(() => {
            this.errors = []
            this.errors.push(this.$t('meeting.service_unavailable'))
          })
        }
      }, 15000);

      /* RUN WHEN USER JOIN CHANNEL */
      this.webSocketChannel.here((users) => {
        users.forEach((user) => {
          console.log(user)
          console.log(this.currentUser)
          if (this.broadcaster_id == user.id) {
            user.isBroadcaster = true
            this.broadcasterIsInChannel = true
            if (this.currentUser.id === user.id) {
              this.currentUser.isBroadcaster = true
            }
          }
          if (this.currentUser.id != user.id) {
            this.users.push(user)
            this.$set(
                this.peers,
                `${user.id}`,
                this.peerCreator(
                    this.$refs.self.srcObject,
                    user,
                )
            );
            this.peers[user.id].create();
            this.peers[user.id].initEvents();
          }
        })
        if (this.broadcasterIsInChannel === null) {
          this.broadcasterIsInChannel = false
        }
      })

      /* RUN WHEN NEW USER JOIN CHANNEL ON OTHER USERS DEVICE */
      this.webSocketChannel.joining((user) => {
        user.isBroadcaster = this.broadcaster_id == user.id;
        if (this.broadcaster_id == user.id) {
          user.isBroadcaster = true
          this.broadcasterIsInChannel = true
          if (this.currentUser.id === user.id) {
            this.currentUser.isBroadcaster = true
          }
        }
        this.users.push(user)
        this.$set(
            this.peers,
            `${user.id}`,
            this.peerCreator(
                this.$refs.self.srcObject,
                user,
            )
        );
        this.peers[user.id].create();
        this.peers[user.id].initEvents();

        if (this.screenIsSharing) {
          this.$set(
              this.peers,
              `screen-${user.id}`,
              this.peerCreator(
                  this.$refs.screen.srcObject,
                  user,
                  true,
              )
          );
          this.peers[`screen-${user.id}`].create();
          this.peers[`screen-${user.id}`].initEvents();
        }

        if (this.whiteBoardStatus) {
          this.$set(
              this.peers,
              `whiteboard-${user.id}`,
              this.peerCreator(
                  document.getElementsByClassName('canvas active')[0].captureStream(),
                  user,
                  false, true,
              )
          );
          this.peers[`whiteboard-${user.id}`].create();
          this.peers[`whiteboard-${user.id}`].initEvents();
        }

        if (this.videoFileIsSharing) {
          let videoEl = this.$refs["video-file"];
          let localVideoStream = null
          if (typeof videoEl.mozCaptureStream == 'function') {
            localVideoStream = videoEl.mozCaptureStream();
          } else {
            localVideoStream = videoEl.captureStream();
          }

          this.$set(
              this.peers,
              `video-file-share-${user.id}`,
              this.peerCreator(
                  localVideoStream,
                  user, false, false, true
              )
          );
          this.peers[`video-file-share-${user.id}`].create();
          this.peers[`video-file-share-${user.id}`].initEvents();
        }

        if (this.audioFileIsSharing) {
          let audioEl = this.$refs["audio-file"];
          let localAudioStream = null
          if (typeof audioEl.mozCaptureStream == 'function') {
            localAudioStream = audioEl.mozCaptureStream();
          } else {
            localAudioStream = audioEl.captureStream();
          }

          this.$set(
              this.peers,
              `audio-file-share-${user.id}`,
              this.peerCreator(
                  localAudioStream,
                  user, false, false, false, true
              )
          );
          this.peers[`audio-file-share-${user.id}`].create();
          this.peers[`audio-file-share-${user.id}`].initEvents();
        }
      })

      /* RUN WHEN USERLEAVE CHANNEL ON OTHER USERS DEVICE */
      this.webSocketChannel.leaving((user) => {
        console.log(user.name, "Left");
        this.peers[user.id].getPeer().destroy();
        if (this.broadcaster_id == user.id) {
          this.broadcasterIsInChannel = false
        }
        delete this.peers[user.id];
        if (user.id == this.currentUser.id) {
          this.users = [];
        } else {
          const leavingUserIndex = this.users.findIndex(
              (data) => data.id === user.id
          );
          this.users.splice(leavingUserIndex, 1);
        }
      });

      /* ANSWER OF OFFER LISTENER. THIS WOULD FINALIZE PEER CONNECTION */
      this.Echo.private(`stream-signal-channel.${this.currentUser.id}`).listen(
          "StreamAnswer",
          ({data}) => {
            console.log("Signal Answer from private channel");
            console.log(data)
            if (data.answer.renegotiate) {
              console.log("renegotating");
            }
            if (data.answer.sdp) {
              const updatedSignal = {
                ...data.answer,
                sdp: `${data.answer.sdp}\n`,
              };
              if (data.screen) {
                console.log('data.screen')
                this.peers['screen-' + data.receiver.id]
                    .getPeer()
                    .signal(updatedSignal);
              } else if (data.whiteBoard) {
                console.log('data.whiteBoard')
                this.peers['whiteboard-' + data.receiver.id]
                    .getPeer()
                    .signal(updatedSignal);
              } else if (data.videoFile) {
                console.log('data.videoFile')
                this.peers['video-file-share-' + data.receiver.id]
                    .getPeer()
                    .signal(updatedSignal);
              } else if (data.audioFile) {
                console.log('data.audioFile')
                this.peers['audio-file-share-' + data.receiver.id]
                    .getPeer()
                    .signal(updatedSignal);
              } else {
                console.log('data')
                this.peers[data.receiver.id]
                    .getPeer()
                    .signal(updatedSignal);
              }
            }
          }
      );

      /* LISTEN FOR SIGNAL OFFER FROM BROADCASTERS */
      this.Echo.private(`stream-signal-channel.${this.currentUser.id}`).listen(
          "StreamOffer",
          ({data}) => {
            console.log("Signal Offer from private channel");
            console.log(data)
            this.createViewerPeer(data.sharer, data.offer, data.broadcaster,
                data.screen, data.whiteBoard, data.videoFile, data.audioFile);
          }
      );

      /* LISTEN FOR END MEETING EVENT */
      this.Echo.private(`streaming-channel.${this.channel}`).listen(
          "MeetingEnded",
          () => {
            console.log("Meeting Ended");
            this.showScore = true
          }
      );

      /* LISTEN FOR MEETING PERMISSION  UPDATING*/
      this.Echo.private(`permissions.${this.currentUser.id}.${this.clas.id}`).listen(
          "MeetingPermissionsUpdated",
          async ({data}) => {
            this.microphonePermission = data.microphone
            this.webcamPermission = data.webcam
            this.screenPermission = data.screen
            this.whiteboardPermission = data.whiteboard
            this.videoPermission = data.video
            this.audioPermission = data.audio
            this.recordPermission = data.record
            if (this.isRecording && !this.recordPermission) {
              this.toggleRecord()
            }
            if (this.audioFileIsSharing && !this.audioPermission) {
              this.cancelAudioSharing()
            }
            if (this.videoFileIsSharing && !this.videoPermission) {
              this.cancelVideoSharing()
            }
            if (this.whiteBoardStatus && !this.whiteboardPermission) {
              this.toggleWhiteBoard()
            }
            if (this.screenIsSharing && !this.screenPermission) {
              this.toggleShareScreen()
            }
            if (!this.mutedVideo && !this.webcamPermission) {
              this.toggleMuteVideo()
            }
            if (!this.mutedAudio && !this.microphonePermission) {
              this.toggleMuteAudio()
            }
          }
      );
    },

    /* CREATE NEW PEER CONNECTION TO SHARE STREAM WITH SPECIFIC USER */
    peerCreator(stream, user, screen = false, whiteBoard = false, videoFile = false, audioFile = false) {
      let peer;
      return {
        create: () => {
          peer = new Peer({
            initiator: true,
            trickle: false,
            stream: stream,
            config: {
              iceServers: [
                {
                  urls: "stun:stun.stunprotocol.org",
                },
                {
                  urls: this.turn_url,
                  username: this.turn_username,
                  credential: this.turn_credential,
                },
              ],
            },
          });
        },
        getPeer: () => peer,
        initEvents: () => {
          /* CALL OFFER API TO SEND OFFER TO OTHER PEER VIA WEBSOCKET */
          peer.on("signal", (offer) => {
            axios
                .post("/api/web-rtc/stream/offer", {
                  sharer: this.currentUser,
                  receiver_id: user.id,
                  offer,
                  screen,
                  whiteBoard,
                  videoFile,
                  audioFile
                })
                .then((res) => {
                  console.log(res);
                })
                .catch((err) => {
                  console.log(err);
                });
          });
          peer.on("stream", (stream) => {
            console.log("onStream");
            console.log(stream);
          });
          peer.on("track", (track, stream) => {
            console.log("onTrack");
            console.log(track)
            console.log(stream)
          });
          peer.on("connect", () => {
            console.log("Broadcaster Peer connected");
          });
          peer.on("close", () => {
            console.log("Broadcaster Peer closed");
          });
          peer.on("error", (err) => {
            console.log("handle error gracefully");
            console.log(err)
          });
        },
      };
    },

    /* CREATE VIEWER PEER TO RECEIVE STREAMS FROM OTHER PEER */
    createViewerPeer(sharer, incomingOffer, broadcaster, screen = false, whiteBoard = false, videoFile = false, audioFile = false) {
      const peer = new Peer({
        initiator: false,
        trickle: false,
        config: {
          iceServers: [
            {
              urls: "stun:stun.stunprotocol.org",
            },
            {
              urls: this.turn_url,
              username: this.turn_username,
              credential: this.turn_credential,
            },
          ],
        },
      });
      peer.addTransceiver("video", {direction: "recvonly"});
      peer.addTransceiver("audio", {direction: "recvonly"});

      /* CALL ANSWER API TO SEND ANSWER OF OFFER TO OTHER PEER VIA WEBSOCKET */
      peer.on("signal", (data) => {
        axios
            .post("/api/web-rtc/stream/answer", {
              broadcaster_id: broadcaster.id,
              answer: data,
              screen, whiteBoard, videoFile, audioFile
            })
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
      });
      peer.on("stream", (stream) => {
        console.log('stream')
        console.log(stream)

        /* HANDLE SCREEN SHARING */
        if (screen) {

          this.screens.push({
            ref: 'screen' + broadcaster.id,
            user: broadcaster
          })
          this.$nextTick(() => {
            this.$refs['screen' + broadcaster.id][0].srcObject = stream;
          })

          /* HANDLE WHITEBOARD SHARING */
        } else if (whiteBoard) {

          this.whiteboards.push({
            ref: 'whiteboard' + broadcaster.id,
            user: broadcaster
          })
          this.$nextTick(() => {
            this.$refs['whiteboard' + broadcaster.id][0].srcObject = stream;
          })

          /* HANDLE VIDEO FILE SHARING */
        } else if (videoFile) {
          this.videoFileIsSharing = true
          this.videoFileSharer = sharer
          this.$nextTick(() => {
            this.$refs["video-file"].srcObject = stream
          })

          /* HANDLE NORMAL AUDIO AND AUDIO SHARING */
        } else if (audioFile) {
          this.audioFileIsSharing = true
          this.audioFileSharer = sharer
          this.$nextTick(() => {
            this.$refs["audio-file"].srcObject = stream
          })

          /* HANDLE NORMAL AUDIO AND VIDEO SHARING */
        } else {
          this.$refs['v' + broadcaster.id][0].srcObject = stream;
        }
      });
      peer.on("track", (track, stream) => {
        console.log("onTrack");
        console.log(track)
        console.log(stream)
      });
      peer.on("connect", () => {
        console.log("Viewer Peer connected");
      });
      peer.on("close", () => {
        console.log("Viewer Peer closed");
        console.log(peer)
        if (screen) {
          const leavingScreenIndex = this.screens.findIndex(
              (data) => data.ref === 'screen' + broadcaster.id
          );
          this.screens.splice(leavingScreenIndex, 1);
        }
        if (whiteBoard) {
          const leavingWhiteboardIndex = this.whiteboards.findIndex(
              (data) => data.ref === 'whiteboard' + broadcaster.id
          );
          this.whiteboards.splice(leavingWhiteboardIndex, 1);
        }
        if (videoFile) {
          this.videoFileIsSharing = false
          this.videoFileSharer = null
        }
        if (audioFile) {
          this.audioFileIsSharing = false
          this.audioFileSharer = null
        }
        peer.destroy();
      });
      peer.on("error", (err) => {
        console.log("handle error gracefully");
        console.log(err)
      });
      const updatedOffer = {
        ...incomingOffer,
        sdp: `${incomingOffer.sdp}\n`,
      };
      peer.signal(updatedOffer);
    },

    toggleMuteAudio() {
      if (this.mutedAudio) {
        this.$refs.self.srcObject.getAudioTracks()[0].enabled = true;
        this.mutedAudio = false;
      } else {
        this.$refs.self.srcObject.getAudioTracks()[0].enabled = false;
        this.mutedAudio = true;
      }
    },

    toggleMuteVideo() {
      if (this.mutedVideo) {
        this.$refs.self.srcObject.getVideoTracks()[0].enabled = true;
        this.mutedVideo = false;
      } else {
        this.$refs.self.srcObject.getVideoTracks()[0].enabled = false;
        this.mutedVideo = true;
        this.mutedScreen = true;
      }
    },

    async toggleShareScreen() {
      if (this.screenIsSharing) {
        this.users.forEach((user) => {
          this.peers[`screen-${user.id}`].getPeer().destroy();
          delete this.peers[`screen-${user.id}`];
        })
        this.screenIsSharing = false
        this.$refs.screen.srcObject = null
      } else {
        await navigator.mediaDevices.getDisplayMedia({
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            sampleRate: 44100
          }, video: true
        }).then(stream => {
          this.screenIsSharing = true
          this.$refs.screen.srcObject = stream

          this.users.forEach((user) => {
            this.$set(
                this.peers,
                `screen-${user.id}`,
                this.peerCreator(
                    this.$refs.screen.srcObject,
                    user,
                    true,
                )
            );
            this.peers[`screen-${user.id}`].create();
            this.peers[`screen-${user.id}`].initEvents();
          })
        })
      }
    },

    toggleWhiteBoard() {
      if (this.whiteBoardStatus) {
        this.users.forEach((user) => {
          this.peers[`whiteboard-${user.id}`].getPeer().destroy();
          delete this.peers[`whiteboard-${user.id}`];
        })
        this.whiteBoardStatus = false
        this.paintConfigStatus = false
        this.whiteBoardWidth = null
      } else {
        this.whiteBoardStatus = true

        this.$nextTick(() => {
          this.whiteBoardWidth = document.getElementById('col-of-paintable')?.clientWidth
        })

        let self = this
        setTimeout(() => {
          self.paintConfigStatus = true
          self.users.forEach((user) => {
            self.$set(
                self.peers,
                `whiteboard-${user.id}`,
                self.peerCreator(
                    document.getElementsByClassName('canvas active')[0].captureStream(),
                    user, false, true,
                )
            );
            self.peers[`whiteboard-${user.id}`].create();
            self.peers[`whiteboard-${user.id}`].initEvents();
          })
        }, 1000)
      }
    },

    /* PAINTABLE METHODS */
    navigate(number) {
      this.paintNumber = (this.paintNumber + number) < 1 ? 1 : (this.paintNumber + number);
    },
    toggledPaintable(paintConfigStatus) {
      this.paintConfigStatus = paintConfigStatus;
    },


    selectVideo() {
      let elem = document.getElementById('video-file-input');
      let evt = document.createEvent("MouseEvents");
      evt.initEvent("click", true, false);
      elem.dispatchEvent(evt);
    },
    videoFileSelected() {
      this.videoFileIsSharing = true
      this.videoFileSharer = this.currentUser
      this.$nextTick(() => {
        let videoEl = this.$refs["video-file"];
        let file = document.getElementById('video-file-input').files[0];
        let type = file.type;
        if (!videoEl.canPlayType(type)) {
          alert('cannot play that file');
          return;
        }
        videoEl.src = URL.createObjectURL(file);
        videoEl.play().then(() => {
          // Mozilla currently prefixes the function name, so we have to check for either
          let localVideoStream = null
          if (typeof videoEl.mozCaptureStream == 'function') {
            localVideoStream = videoEl.mozCaptureStream();
          } else {
            localVideoStream = videoEl.captureStream();
          }

          if (localVideoStream) {
            this.users.forEach((user) => {
              this.$set(
                  this.peers,
                  `video-file-share-${user.id}`,
                  this.peerCreator(
                      localVideoStream,
                      user, false, false, true
                  )
              );
              this.peers[`video-file-share-${user.id}`].create();
              this.peers[`video-file-share-${user.id}`].initEvents();
            })
          }
        });
      })
    },
    cancelVideoSharing() {
      this.users.forEach((user) => {
        this.peers[`video-file-share-${user.id}`].getPeer().destroy();
        delete this.peers[`video-file-share-${user.id}`];
      })
      this.videoFileIsSharing = false
      this.videoFileSharer = null
      this.$refs[`video-file`].srcObject = null
    },

    selectAudio() {
      let elem = document.getElementById('audio-file-input');
      let evt = document.createEvent("MouseEvents");
      evt.initEvent("click", true, false);
      elem.dispatchEvent(evt);
    },
    audioFileSelected() {
      this.audioFileIsSharing = true
      this.audioFileSharer = this.currentUser
      this.$nextTick(() => {
        let audioEl = this.$refs["audio-file"];
        let file = document.getElementById('audio-file-input').files[0];
        let type = file.type;
        if (!audioEl.canPlayType(type)) {
          alert('cannot play that file');
          return;
        }
        audioEl.src = URL.createObjectURL(file);
        audioEl.play().then(() => {
          // Mozilla currently prefixes the function name, so we have to check for either
          let localAudioStream = null
          if (typeof audioEl.mozCaptureStream == 'function') {
            localAudioStream = audioEl.mozCaptureStream();
          } else {
            localAudioStream = audioEl.captureStream();
          }

          if (localAudioStream) {
            this.users.forEach((user) => {
              this.$set(
                  this.peers,
                  `audio-file-share-${user.id}`,
                  this.peerCreator(
                      localAudioStream,
                      user, false, false, false, true
                  )
              );
              this.peers[`audio-file-share-${user.id}`].create();
              this.peers[`audio-file-share-${user.id}`].initEvents();
            })
          }
        });
      })
    },
    cancelAudioSharing() {
      this.users.forEach((user) => {
        this.peers[`audio-file-share-${user.id}`].getPeer().destroy();
        delete this.peers[`audio-file-share-${user.id}`];
      })
      this.audioFileIsSharing = false
      this.audioFileSharer = null
      this.$refs[`audio-file`].srcObject = null
    },

    async toggleRecord() {
      if (this.isRecording) {

        this.isRecording = false
        clearInterval(this.recordInterval)
        this.mediaRecorder.stop()

      } else {

        this.isRecording = true
        this.recordInterval = setInterval(() => {
          this.recordIcon = (this.recordIcon === 1 ? 2 : 1)
        }, 1000)

        const displayStream = await navigator.mediaDevices.getDisplayMedia({video: true, audio: true});
        const voiceStream = await navigator.mediaDevices.getUserMedia({audio: true, video: false});
        let tracks = [...displayStream.getTracks(), ...voiceStream.getAudioTracks()]
        const stream = new MediaStream(tracks);

        const mime = MediaRecorder.isTypeSupported("video/webm; codecs=vp9")
            ? "video/webm; codecs=vp9"
            : "video/webm"

        this.mediaRecorder = new MediaRecorder(stream, {
          mimeType: mime
        })

        let chunks = []
        this.mediaRecorder.addEventListener('dataavailable', function (e) {
          chunks.push(e.data)
        })

        let self = this
        this.mediaRecorder.addEventListener('stop', function () {
          let blob = new Blob(chunks, {
            type: chunks[0].type
          })
          let url = URL.createObjectURL(blob)

          let data = new FormData();
          data.append('file', blob);
          data.append('meeting_id', self.meeting_id);
          console.log(data)
          axios.post('/api/web-rtc/save-recorded-file', data).then((res) => {
            console.log(res.data)
          })

          let a = document.createElement('a')
          a.href = url
          a.download = 'video.webm'
          a.click()
        })

        //we have to start the recorder manually
        this.mediaRecorder.start()
      }
    },

    endMeeting() {
      Object.entries(this.peers).forEach((peer) => {
        peer[1].getPeer().destroy();
      })
      clearInterval(this.meetingOngoingInterval)
      this.Echo.leave("streaming-channel." + this.channel)
      this.Echo.leave(`stream-signal-channel.${this.currentUser.id}`)
      this.errors = []
      this.showScore = true
    },

    toggleFullScreen(id) {
      let elem = document.getElementById(id)
      if (elem.style.position === 'absolute') {
        elem.style.position = 'relative'
        elem.style.zIndex = '0'
      } else {
        elem.style.position = 'absolute'
        elem.style.width = '100%'
        elem.style.height = '100%'
        elem.style.left = '0'
        elem.style.top = '0'
        elem.style.zIndex = '100'
      }
    },

    submitScore() {
      axios.post('/api/web-rtc/meeting/score/' + this.meeting_id, {
        score: this.score
      }).then(() => {
        this.scoreSubmitted = true
      })
    }
  },
}
</script>

<style scoped>
.fullscreen_center_box {
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex
}

.fullscreen_center_box_absolute {
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  z-index: 99;
  background-color: #ffffff
}

.bordered-card {
  border: 1px solid black;
  border-radius: 15px !important;
  padding: 10px 10px 40px;
}

.control {
  width: 100%;
}

.paint {
  border: 2px solid #000;
  border-radius: 10px;
  box-sizing: border-box;
  display: block;
  width: 100% !important;
  height: 450px !important;
  position: relative !important;
  overflow: hidden;
}

.custom-navigation {
  padding: 15px;
  margin-right: 10px;
  z-index: 1001;
  background-color: #fff;
  border-radius: 15px;
  border: 1px solid black;
}

.under-video-layer {
  height: 40px;
  /*display: flex;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  bottom: 0;
  position: absolute;
  padding: 0 10px;
  width: 100%;
  right: 0;
  left: 0;
}

.under-video-layer {
  height: 40px;
  bottom: 0;
  position: absolute;
  padding: 0 10px;
  width: 100%;
  right: 0;
  left: 0;
  text-align: right !important;
}

.under-video-layer-self {
  height: 40px;
  bottom: 0;
  position: absolute;
  padding: 0 10px;
  width: 100%;
  right: 0;
  left: 0;
  text-align: right !important;
}

.current-user-chip {
  float: left;
  margin-top: 4px;
  color: white
}

.user-chip {
  float: left;
  margin-top: 4px;
}

.watermark {
  position: absolute;
  z-index: 99999;
  font-size: 20px;
  color: black;
  opacity: 0.30;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  animation: 60s moving-watermark infinite;
  max-width: 100px;
}

@keyframes moving-watermark {
  0% {
    left: 5%;
    top: 5%;
  }
  15% {
    left: 85%;
    top: 5%;
  }
  30% {
    left: 42.5%;
    top: 42.5%;
  }
  50% {
    left: 85%;
    top: 85%;
  }
  65% {
    left: 5%;
    top: 85%;
  }
  80% {
    left: 42.5%;
    top: 42.5%;
  }
  100% {
    left: 5%;
    top: 5%;
  }
}
</style>